.SideBarMenu {
	position: relative;
	z-index: 9999;

	.fixMenu{
		width: 100%;
		padding: 1vw;
		position: fixed;

		&.expanded{
			display: flex;
		}
		&.collapse{
			position: fixed;
    		z-index: 999;
			overflow: scroll;
		}
	}

	.menuButton {
			.hamburgerIcon {
				border: none!important;
				box-shadow: none!important;
				background-color: transparent!important;
				color: #B6862D!important;
				font-size: 6.5vw!important;
				width: 100%!important;
				height: auto!important;
				padding: 0 5vw 0 2vw!important;
				margin: 0!important;
				line-height: 1;
			}
		}

	@media (min-width: 480px) {
		&.expanded {min-width: 160px;}
		&.collapse {width: 79px;}

		.fixMenu{
			display: initial;
			position: fixed;
			padding: 0 6px 0 6px;
			height: 100%;
			overflow-y: scroll;
			width: 80px;

			.up{display: none;}
			.down{display: initial;}

			&.expanded {
				display: initial;
				width: 160px;

				.up{display: initial;}
				.down{display: none;}
			}
		}

		.menuButton {
			.hamburgerIcon {
				font-size: 3vh!important;
				padding: 0!important;
			}
			
		}
	}
}