@import "variables";

.modal-widget {padding: 0;}

.box-widget {
  background: $gray_low;

  div * {padding: 10px;}

  .widget-header {
    border-bottom: 1px solid $gray_medium;

    .action-widget {
      padding: 5px 0px!important;
      border: 1px solid transparent;

      &.active{
        background: $gray_medium;
        color: $white;
        border: 1px solid $gray_low;

        &:hover {
          background: $gray_high;
          color: $white;
          border: 1px solid $gray_medium;

          i {color: $white;}
        }
      }

      &:hover {
        background: $gray_medium;
        color: $white;
        border: 1px solid $gray_low;
      }
    }
  }

  .widget-footer {
    border-top: 1px solid $gray_medium;
  }
}

.contenido-widget {
  position: relative;
  width: 100%;

  img {border: 1px solid $gray_low;}
}

.modal-dialog {
  max-width: none !important;
  margin: auto 10vw;
}
