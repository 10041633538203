.SideBarMenuItemView {
	text-align: center;
	
	a {
		cursor: pointer;
		color: #393839;
		text-decoration: none;
		width: 100%;
		border-radius: 5px;
		display: table;

		&:hover {
			background-color: #B6862D;
			color: white;
		}
	}
	.ItemContent {
		padding: 0 0 7px 0;

		.label {display: none!important;}
		.icon {
			font-size: 7vw;
			padding: 0 1.5vw;
			line-height: 1;
			border-right: 1px solid #a7a7a7;
		}
		.icon:last-child{border: none;}

		&.collapsed {
			padding: .5vh 0;
			border-top: 1px solid rgb(134, 134, 134);
			width: 100%;
			display: block;

			.label {display: initial!important;}
			.icon { font-size: 5vh; border: none;}
		}
	}

	@media (min-width: 480px) {
		border-top: 1px solid rgb(134, 134, 134);

		.ItemContent {
			padding: 1vh .5vh;
			font-size: 2vh;

			.label {display: initial!important;}

			.icon {
				font-size: 3.5vh;
				border: none;
			}

			&.collapsed {
				border-top:none;
				width: 60px;

				.label {display: none!important;}
				.icon { font-size: 4vh; padding: 2vh 0;}
			}
		}
	}
}

@keyframes tooltip {
		from {opacity: 0;}
		to {opacity: 1;}
	}