@import "variables";


.flex-container {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.distanciador {height: 100vh;}
.distanciador-header{height: calc(100vh - 90px);}
.page {margin: 4vh;}

.align-items-right {
  text-align: right;

  button {margin-left: 10px;}
}

.separation-bottom, .separation {
  border-top: 1px solid $gray_low;
  width: 100%;
}

.separation {margin: 2vh 0!important;}

.separation-bottom {margin: 0 0 1vh!important;}


.tag {
  display: inline-block;
  width: auto;
  background: $gray_medium;
  color: $white!important;
  padding: 5px 10px;
  border-radius: $radius;
  margin-right: 5px!important;
  text-transform:lowercase;
  font-size: 11px!important;
  text-wrap: nowrap;
}


.bk-gray {
  background: $gray_low;
  padding: 2vh;
}

.encabezado {
  text-align: center!important;

  * {text-align: center!important;}
}

.pie-imagen {margin-bottom: 2vh;}


.Menu-body, .Menu-action {
  height: 0;
  margin: 0!important;
  overflow: hidden;
  opacity: .0;

  &.active{
    margin:  0 2vh 2vh!important;
    height: auto;
    opacity: 1;

    transition: opacity 1000ms;
  }
}

.Menu-action, .Menu-action-widget {
  span {
    display: block;

    a:not(:first-of-type){
      border-left: 1px solid;
      padding-left: 10px;
    }

    a {
      padding: 0 10px 0 0;
      font-size: 12px!important;
      letter-spacing: 0;
    }
  }

  .delete{
    display: block;
    font-size: 12px!important;
    color: $error!important;
    margin-top: 1vh;
  }
}

.Menu-action-widget {margin:  0 1vh 2vh;}
.Menu-action {margin:  0 2vh 2vh;}

.selected-img {
  cursor: pointer;
  border: 1px dashed $gray_high;
  border-radius: $radius;
  padding: 1.5vh;
	margin: 0 0 3vh!important;
  width: 100%;
  max-width: 100%!important;
  box-shadow: $shadow $gray_medium;

  i {
    font-size: 30px;
    margin-bottom: 1vh;
  }

  * {
    width: 100%;
    display: block;
    text-align: center!important;
  }
}


.descripcion-post{
  max-width:1000px;
  margin: 0 auto;
}
