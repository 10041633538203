@import "variables";





::-webkit-scrollbar {width: 10px;}
::-webkit-scrollbar-track {background: $gray_low; border-left: 1px solid $gray_medium;}
::-webkit-scrollbar-thumb {background: $gray_medium;}
::-webkit-scrollbar-thumb:hover {background: $gray_high;}

html {
	scroll-behavior: smooth;

	::selection {
		background-color: $gray_high;
		color: $gray_low;
	}
}

body {
	font-family: $font-special, Arial, Helvetica, sans-serif!important;
	font-weight: lighter!important;
	color: $text;
	line-height: 1.3;
	text-align: left;

	* {word-wrap: break-word;}
}

h1, h2, h3, h4, h5, h6, input, textarea, select, a, button, b, strong, small {color: $gray_high; word-break: break-word;}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-title, Arial, Helvetica, sans-serif!important;
	font-weight: bold!important;
	margin-bottom: 1vh;
	text-align: left;
}

input, textarea, select, a, button, small, sup {
	font-family: $font-special, Arial, Helvetica, sans-serif!important;
}

b, strong, span {
	font-family: $font-body, Arial, Helvetica, sans-serif!important;
}

h1, h2, h3, h4, h5, h6, p, span, small {word-spacing: 2px;}
h1, h2, h3, h4, h5, h6 {margin-bottom: 1vh;}

h1 {letter-spacing: .18vw!important;	font-size: 39px!important;}
h2 {letter-spacing: .14vw!important;	font-size: 35px!important;}
h3 {letter-spacing: .10vw!important;	font-size: 31px!important;}
h4 {letter-spacing: .6vw!important;	font-size: 25px!important;}
h5 {letter-spacing: .2vw!important;	font-size: 21px!important; border-bottom: 1px solid $gray_low;}
h6 {letter-spacing: .0vw!important;	font-size: 17px!important;}

p, label, a, button {letter-spacing: .3px; font-size: 16px!important;}
small, span {letter-spacing: 2px; font-size: 14px!important;}

p:not(:first-of-type) {margin: 15px 0;}

small {
	display: block;

	b {color: $gold}
}

h2, h5{
	&::after {
		content: "";
		margin-bottom: 0.8rem;
		margin-left: 0.1rem;
		height: 2px;
		background-color: $gray_high;
		display: block;
		padding-bottom: 0;
		line-height: 1;
	}
}

h2::after {width: 16px;}
h5::after {width: 11px;}

h5 {
	button {
		margin: 0!important;
		min-height: 30px;
		float: right;
	}
}

sup {
	font-size: 10px;
	margin-left: 5px;
	top: -15px;
	color: $gray_medium;
	font-weight: lighter;

	&::before {content: "[";}
	&::after {content: "]";}
}


.none {display: none;}
@keyframes logo {
	0%	{opacity: 0; content: url("../imgs/logos/ChevyPlan-Blanco-corbatin.svg");}
	50%	{opacity: 1; content: url("../imgs/logos/ChevyPlan-Blanco-corbatin.svg");}
	100%	{opacity: 0; content: url("../imgs/logos/ChevyPlan-Blanco-corbatin.svg");}
}

img {
	border-radius: $radius;
	max-width: 100%;
	margin: 0 auto;

	&.showAnimate {
		view-timeline-name: --revealing-img;

		animation-name: show-img;
		animation-fill-mode: both;
		animation-timeline: --revealing-img;

		animation-range: entry 0% cover 50%
	}
}

i {
	margin-right: 5px;
	line-height: 1;
}

@keyframes show-img {
	from {
		opacity: 0;
		scale: .8%;
		background: $gray_high;
	}

	to {
		opacity: 1;
		scale: 100%;
	}
}

ol, ul {
	margin: 2vh 20px 1vh;

	&.organizationMenu {
		margin: 0;
	}

	li {list-style: disc;}
}

.form-group {
	height: auto;
	width: 100%;
	padding: 2vh;
	margin-bottom: 2vh;
	display: grid;
}

.border-group {
	border: 1px solid $gray_low;
	border-radius: $radius;
	box-shadow: $shadow $gray_low;
}


.top-scroll {
	position: fixed;
	bottom: 1vh;
	right: 1vh;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	background: $gold;
	color: $white;
	padding: 15px;

	i {font-size: 30px;}
}



@mixin transition($property) {transition: $property 0.5s ease-in-out;}




.caja_principal {
	border: 1px solid $gray_low;
	border-radius: $radius;
	box-shadow: $shadow $gray_low;

	background-color: $white;
	padding: 2vh;

	.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.css-1mbunh-MuiPaper-root {
		box-shadow: none!important;
	}

	h1 {
		font-weight: 400;
		text-transform: uppercase;
		text-align: center;
		padding: 0 0 1vh;
		border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	}
	&.middle {
		max-width: 900px;
		margin: auto;
	}

	button {
		&.icon{
			width: auto;
			.label, .icon{
				float: left;
				font-size: 2.5vw;
				line-height: 1;
				padding: 0 10px;
				margin: 0!important;
			}
		}
	}
	svg {
		padding-bottom: 13px;
	}
}



button, .accept {
	width: 100%;
	background-color: $gold!important;
	color: $white!important;
	padding: 10px 20px!important;
	border-radius: $radius;
	border: 1px solid $gold!important;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.411)!important;
	margin-top: 1vh!important;
}


.MenuTop {
	.MuiBottomNavigation-root {
		height: auto;
		padding: 4vh 1vh;

		button{ margin: 1px!important; border-radius: 0!important;}
		button:first-child{ border-radius: 8px 0 0 8px!important;}
		button:last-child{ border-radius: 0 8px 8px 0!important;}
	}

	button {
		svg {
			font-size: 50px;
		}

	}

	.css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root {
		padding: 2.5vw;

		&.Mui-selected {
			background: #393839!important;
			box-shadow: inset 0 0 5px 7px #26262681 !important;
		}
	}
}


@media (min-width: 480px) {
	.body {display: flex;};
}

.content {
	width: 100%;
	height: 100%;
	padding: 0 2.5% 2.5% 2.5%;
}

.slider {
	text-align: center;
	width: 100%;

	img {
		width: auto;
		max-width: 450px;
		margin: 1vh 0;
	}
};

.center_note {
	text-align: center;
	border-bottom: 1px solid;
}



.MuiDialog-root.MuiModal-root.css-zw3mfo-MuiModal-root-MuiDialog-root {
    z-index: 9999;
}