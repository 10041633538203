@import "variables";

.modal {
		&-header {
			padding: 2rem;
			border: none;

			.modal-title {
				h5{
					margin: 0!important;
					&:after { margin-bottom: 0;}
				}
			}

			.close {
				padding: 0;

				span {
					color: $gray_high!important;
					font-size: 40px!important;
					font-weight: lighter;
				}
			}
		}

		&-content {
			border: 1px solid $gray_high;
			border-radius: $radius;

			p{font-weight: lighter;}
		}
}

.swal2-container {z-index: 9999;}
.swal2-popup.swal2-modal.swal2-show{
	.swal2-icon {
		span {font-size: 16px!important;}
	}

	.swal2-title {
		&:after {display: none;}
	}

	button {
		&::after {display: none;}
	}
}

.group-action {
	border-right: 1px solid $gray_medium;
	padding: 2px!important;
	margin-bottom: 5px;
}

.swal2-html-container{
	.error {
		display: block;
		margin-top: 4vh;
		border: 1px dotted $gray_medium;
		border-radius: $radius;
		padding: 1vh;

		* {
			display: block;
			margin-bottom: .5vh;
			text-align: center!important;
		}
	}

	.error2 {
		color: $gray_medium;
		position: absolute;
		width: 100%;
		bottom: 2px;
		left: 0;
	}
}

.modalList {margin-top: 2vh;}

div:where(.swal2-icon){
	margin: 2em auto 0!important;
	border: none!important;
}

button.swal2-confirm.swal2-styled {background: $gold;}

button.swal2-cancel.swal2-styled {
	background: $white!important;
	color: $gray_high!important;
	border: 1px solid!important;
}
