

@media (max-width: 480px) {
.expanded .SideBarMenuCardView{
	display: none;
}
}

.SideBarMenuCardView {
	text-align: center;

	.MuiAvatar-root {margin: auto;}

	.profile {
		display: none;

		&.collapsed {
			display: initial;
			border-radius: 50%;
			width: 60px;
			height: 60px;
			padding: 1%;
		}
	}
	.profileInfo {
		display: none;

		.name {
			color: #B6862D;
			font-weight: bold;
			line-height: 1;
			margin: 1vh 0 .5vh 0;
		}
		&.collapsed {
			display: initial;
		}
	}


	@media (min-width: 480px) {
		padding: 1vh 0;

		.profile {
			display: initial;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			padding: 1%;

			&.collapsed {
				width: 30px;
				height: 30px;
				padding: 1%;
			}

		}
		.profileInfo {
			display: initial;
			overflow: hidden;

			.title {line-height: 1;margin:2vh 0  3vh;}

			&.collapsed {
				opacity: 0;
				height: 0;
				display: none;
			}
		}
	}
}
