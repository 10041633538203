// COLORES CONSTITUCIONALES
$title:				#393839;
$text:				#53555A;
$gold:				#B6862D;

// ESCALA PERMITIDA
$black:				#000000;
$blue:				#35343e;
$gray_high:		#393839;
$gray_medium:	#AFAFAF;
$gray_low:		#EFEFEF;
$white:				#ffffff;

$visited:		#919191;
//ALERTAS
$error:				#f44336;
$warning:			#ff9800;
$information:	#2196F3;
$success:			#4CAF50;


$alert_bk_error:	#f2dede;
$alert_cl_error:	#a94442;
$alert_br_error:	#ebccd1;

$alert_bk_warning:	#fcf8e3;
$alert_cl_warning:	#8a6d3b;
$alert_br_warning:	#faebcc;

$alert_bk_information:	#d9edf7;
$alert_cl_information:	#31708f;
$alert_br_information:	#bce8f1;

$alert_bk_success:	#dff0d8;
$alert_cl_success:	#3c763d;
$alert_br_success:	#d6e9c6;

//ACCIONES
$disabled:		#AFAFAF;

// MEDIA SCREEN
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// VISUAL
$radius: 8px;
$shadow: 1px 1px 3px 0px;

// FUENTES
@font-face {
	font-family: "LouisBold";
	src: url("../fonts/LouisBold.ttf") format("truetype");
}

@font-face {
	font-family: "LouisRegular";
	src: url("../fonts/LouisRegular.ttf") format("truetype");
}

@font-face {
	font-family: "GothamBook";
	src: url("../fonts/gotham-book.ttf") format("truetype");
}

$font-title:	"LouisBold";
$font-body:		"LouisRegular";
$font-special:	"GothamBook";
