@import "variables";

table {
	border: none!important;
	border-radius: $radius!important;
	box-shadow: $shadow $gray_medium;

	width: 100%;
	overflow-x: scroll;

	* {vertical-align: middle;}

	thead, tfoot {
		background: $gray_high;
		color: $white;
		line-height: 1.5;

		th {
			text-wrap: nowrap;
			font-size: 12px;

			i {
				position: relative;
				top: -1px;
				font-size: 13px!important;
			}
		}

		::selection {
			background-color: $gray_low!important;
			color: $gray_high!important;
		}
	}

	tbody {
		tr {
			background: $white;

			&:nth-child(even) { background: $gray-low;}
		}

		td {font-size: 13px;}
	}

	th, td {
		padding: 5px 10px!important;
		font-weight: lighter !important;
		vertical-align: middle;
		text-align: center;

		&:first-child {text-align: left;}
	}

	&.left{
		th, td {
			text-align: left;
		}
	}

	a {
		i {display: contents;}

		margin-right: 10px;
		&:last-child{margin: 0;}
	}
}

@media screen and (max-width: $lg){
	table {
	* {width: 100%;}
		display: block;
	}
}

@media screen and (max-width: $md){
	table {
	* {text-wrap: nowrap!important;}
	}
}











.caja_secundaria {
	h2 {margin-top: 5vh;}

	.MuiPaper-root{
		box-shadow: 0 0 1vh rgba(0, 0, 0, 0.3)!important;
		border-radius: $radius!important;
		border: 1px solid #55565A;
	}
	table {
		thead {	
			background: #393839;
			
			th {
				text-transform: uppercase;
				padding: 10px;
				color: white;
				border-right: 1px solid rgba(255, 255, 255, 0.1)!important;
				&:last-child{border-right: None!important;}
			}
		}
		tbody {
			background: white;
	
			tr:nth-child(odd) {background: rgb(252, 252, 252);}
			tr:nth-child(even) {background: rgb(250, 250, 250);}
	
			td {
				border-right: 1px solid rgba(0, 0, 0, 0.1)!important;
				padding: 10px 20px;
				&:last-child{border-right: None!important;}
			}
			td svg {
				font-size: 30px;
				padding: 0;
			}
			td p{
				float: left;
				padding: 0;
				margin: 0;
				text-transform: capitalize;
			}
			button {
				width: 100%;
				background-color: transparent!important;
				color: #393839 !important;
				padding: 0!important;
				margin: 0!important;
				border-radius: $radius!important;
				border: none!important;
				box-shadow: none!important;
			}
			.bodyContent {
				background-color: rgb(250, 250, 250);
	
				p {
					width: 100%;
					font-size: 16px;
					font-weight: bold;
					border-bottom: 1px solid rgba(0, 0, 0, 0.11);
					padding: 3px 0;
	
					span {
						float: right;
						font-weight: lighter;
					}
				}
			}
		}
	}
	.css-1q1u3t4-MuiTableRow-root{
		background-color: #55565A;

		.MuiTypography-root{
			color: #393839;
			text-transform: uppercase;
			font-size: 20px;

			.MuiTableRow-head {
				color: #393839!important;
				border: 1px solid;
			}
		}
	}

}

.SuperDialog{
    padding: 2vh;
	text-align: left;
	border-radius: $radius;

	h4{
		margin-top: 35px;
		text-transform:uppercase;
		text-align: center;

		span {
			font-size: 34px;
			border-bottom: 1px solid;
		}
	}

	button{
		width: 100%;

		&.cancel {
			background: $error !important;
			border: 1px solid $error!important;
			position: absolute;
			top: 0;
			right: 0;
			width: 30px;
			margin: 0!important;
		}
		&.accept {background: $success!important;}
	}
}

.infoCaja {
    padding: 2vh!important;

	.CajaTexto{ 
		p {
			padding: 0;
			width: 100%;
			font-size: 16px;
		}
		&.right{
			text-align: right!important;
		}
		span{
			font-weight: bold;
			font-size: 18px;
		}
	}
	.CajaBorde{
		border-left: 1px solid #55565A;
		height: 10vh;
		padding-left: 2vh;
	}
}