@import "variables";





.back-carnet {
	background: $gray_low;
}

.content-carnet {
	background-color: $white;
	background-size: contain !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-attachment: contain !important;
	background-size: cover !important;

	border: 1px solid $gray_high;

	margin: 10px auto!important;
	max-width: 100%;

	overflow: hidden!important;

	h2 {
		font-size: 21px!important;
		margin-top: 0px;
		color: $gold;

		&:after {display: none!important}
	}
	h3 {font-size: 17px!important;}

	&.front {
		.header {
			background: $gray_high;
			padding: 20px;
		}

		.caja-foto {
			width: 120px;
			height: 120px;
			margin: 10px auto 0;
			border-radius: 50%;
			overflow: hidden;
			text-align: center;

			.foto {
				width: auto;
				height: 120px;
				position: relative;
			}
		}

		.identificacion {
			padding: 20px;

			* {text-align: center;}

			h3 {
				color:$gold;
				font-size: 17px!important;
				margin: 10px 0 20px;
			}
		}
	}

	&.back {
		.inter {padding: 0 25px;}
	}
}
