@import "variables";

input, select, textarea {
	position: relative;
	width: 100%;
	min-height: 35px!important;
	border: 1px solid $gray_medium;
	border-radius: $radius;
}

textarea {
	min-height: 50px!important;
	resize: vertical;
}

select {cursor: pointer;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
}

input[type	= "date"]::-webkit-calendar-picker-indicator,
input[type	= "datetime-local"]::-webkit-calendar-picker-indicator,
input[type	= "month"]::-webkit-calendar-picker-indicator {
	cursor: pointer;
	background: transparent;
	color: transparent;
	height: auto;
	width: auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

input[type	= "checkbox"] {
	cursor: pointer;
	height: 15px;
	min-height: 15px!important;
	width: 15px;
	margin: 10px 10px 0 0;
	line-height: 15px;
	float: left;

	&:before, &:after, &:checked:after {
		content: "";
		transition: all 200ms;
	}

	&:before, &:after {
		position: relative;
		display: block;
		width: 15px;
		height: 15px;
		left: 0px;
		border: 1px solid $gray_medium;
		background-color: $gray_low;
	}

	&:before {top: 0px;}

	&:after {
		top: -15px;
		z-index: 1;
	}

	&:checked:after {
		background-color: $gold;
		border-color: $gold;
		font-size: 15px;
	}

	&:disabled:after {-webkit-filter: opacity(0.4);}
}

.inputColor {
	* {width: auto;}

	input[type	= "color"]{
		height: 35px;
		width: calc(100% - 120px);
		border: 1px solid $gray_medium;
		border-radius: 8px 0 0 8px!important;
		padding: 2px;
		outline: none;

		-webkit-appearance: none;
	}

	input[type	= "text"]{
		border-radius: 0 8px 8px 0 !important;
		width: 120px;
		top: -4px;
	}
}

input[type	= number] {-moz-appearance: textfield;}

input[type	= file] {display: none;}

label {
	&.typeFile {
		cursor: pointer;

		border-radius: $radius;
		border: 2px solid $gray_medium;
		font-size: 15px!important;
		text-align: center;

		&:active, &:focus{
			background: $gray_high;
			color: $gray_low
		}
	}
}

.form-group {
	.checkbox-box {margin-bottom: 1vh;}

	input, textarea, select {
		padding: 0 1vw;
		line-height: 2;
	}

	label {
		margin-top: 1vh;

		&:after {
			content: " ";
			color: $warning
		}
	}

	small, p {text-align: justify;}

	input:focus, textarea:focus, select:focus {
		background-color: $gray_low;
		outline-color: $gold;
	}

	div {
		&:has(input:required), &:has(textarea:required), &:has(select:required) {
			> label{
				&:after {
					content: "*";
					color: $gold!important
				}
			}
		}
	}

	.InputInfo {
		span {display: block;}
	}

	.required {
		small {
			display: block;
			color: $error!important;
			border-radius: $radius;
			margin: 0 0 1vh;
		}
	}

	.error {
		input, textarea, select {border: 1px solid $error;outline-color: $error;}
		label {color:$error;}
	}

	:disabled {
		cursor: not-allowed;
		background: $disabled!important;
		color: $white!important;
	}

	.option_import {
		display: block;
		text-align: right;
		color: $gold!important;
		font-size: 14px!important;
		margin:.5vh 0 1vh;
	}

	button {
		margin-top: 1vh;

		.secondary {
			span {color: $white!important;}
		}
	}
}


form {
	button.MuiButtonBase-root{
		background: none!important;
		border: none!important;
		color: black!important;
		margin: 0!important;
		border-radius: 0!important;
		box-shadow: none!important;
	}

	.MuiGrid-root{
		&.MuiGrid-container {
			border-bottom: 1px solid #00000033;
			margin: 0;
		}
		&.MuiGrid-item {
		padding: 0;
		width: 100%!important;}
	}

	span {
		color: #B6862D;
		font-weight: bold;
		margin-right: 5px;
	}
	.caja-alerta {
		background: white;
		padding: 3vh 1vh;
		text-align: center;
		margin: 5vh 5vw 0;
		border-radius: 30px;
	}
	.Alert {
		color: #AB0C0C;
		font-size: 2vh!important;
		font-weight: 400;
		width: 100%;
		margin-top: 0!important;
		small {
			color: black;
		}
	}

	label.form-label {
		margin-top: 2vh;
		margin-bottom: 0;
	}

	option.void {
		display: none;
	}

	.invi{height: 0!important;width: 0;padding: 0; margin: 0;}
}


.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin: 0!important;
}


	
button.MuiButtonBase-root{
	&.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {background: none!important;}
	
	&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiClock-amButton.css-1eam32d-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton,
	&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiClock-pmButton.css-1wahiw3-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton,
	&.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today.css-195y93z-MuiButtonBase-root-MuiPickersDay-root,
	&.PrivatePickersYear-yearButton.css-3eghsz-PrivatePickersYear-button,
	&.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root,
	&.MuiIconButton-root.MuiIconButton-sizeSmall.MuiPickersCalendarHeader-switchViewButton.css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton,
	&.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeSmall.MuiPickersArrowSwitcher-button.css-1ae9t7h-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
	&.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeSmall.MuiPickersArrowSwitcher-button.css-jro82b-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button
	{
		border: none!important;
		color: black!important;
		margin: 0!important;
		border-radius: 0!important;
		box-shadow: none!important;
	}
	&.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeSmall.MuiPickersArrowSwitcher-button.css-1ae9t7h-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
	&.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeSmall.MuiPickersArrowSwitcher-button.css-jro82b-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button
	{width: auto;}
	&.MuiButtonBase-root.MuiPickersDay-root.Mui-selected{
		border: none!important;
		color: black!important;
		margin: 0!important;
		border-radius: 0!important;
		box-shadow: none!important;
	}
}


button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiPickersCalendarHeader-switchViewButton.css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
    display: none;
}


