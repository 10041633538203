@import "variables";





.dashboard {
	.summary {
		.col-lg {
			border-right: 1px solid $gray_low;
			padding-right: 2vw;
			margin-right: 2vw;
		}
	}
}

.action-page {
	margin-bottom: 4vh;
	position: sticky;
	top: 2vh;
	background: white;
	padding: 1vh 0;
	z-index: 2;
	border: 1px solid $gray_medium;
	border-radius: $radius;
	box-shadow: $shadow $gray_medium;

	a {
		* {display: initial;}
	}

	button {
		width: 100%!important;
		margin-top: 1vh!important;
	}

	h5 {
		margin-left: 22px;
		margin-top: 0;
		margin-bottom: 0;
		border:none!important;

		&:after {
			margin-bottom: 0;
		}
	}

	&.process_content{
		.container-fluid .row section{border-right: 2px solid #b6862d;animation:slide-left .5s cubic-bezier(.25,.46,.45,.94) both;}
		.container-fluid .row section:last-child{border-right: none;}

		img {
			float: left;
			width: 40%;
			margin-right: 1vh;
		}

		h6 {margin: 0;}
		p {margin: 0 0 1vh;}
	}

	.form-group {
		margin: 0;
		padding: 0;
	}
}

@media screen and (max-width: $lg){
	.dashboard {
		.table-organization {
			.col-lg-6 {padding: 0!important;}
		}
	}
}

@media screen and (min-width: $md){
	.dashboard {
		display: grid;
		grid-template-areas:"aside content";
		grid-template-columns: 0.5fr 2fr;

		aside {grid-area: aside;}
		article {grid-area: content;}

		.summary {
			.col-lg {
				&:first-child {padding-left: 0!important;}
				&:last-child {
					padding-right: 0!important;
					border-right: none;
					padding-right: 0;
					margin-right: 0;
				}
			}
		}

		.table-organization {
			.col-lg-6 {
				&:first-child {padding-left: 0!important;}
				&:last-child {padding-right: 0!important;}
			}
		}
	}
}

@media screen and (min-width: $xl){
	.action-page {
		button {
			width: auto!important;
		}
	}
}

.angular-editor-toolbar, .angular-editor-toolbar-set, .color-label {
	background: none;
	padding: 0;
	margin: 0;
}

.angular-editor-toolbar {
	background: $gray_medium!important;

	button, select {
		height: auto!important;
		margin: 0 2px;
		line-height: 35px!important;
		background: $gray_high!important;
		color: $gray_low!important;
		padding: 0!important;

		i {margin: 0!important;}
	}
}

.angular-editor-textarea {
	margin-top: 0!important;
}

.InfoErrorList {
  text-align: left;

  h2, h5 {color: $gold;margin-top: 1vh;}
  h2 {text-align: center;}
}

.InfoError, .InfoErrorT {
	span {
		display: block;
		text-align: left!important;
	}

	.col-md {
		span {
			display:initial;
			text-wrap: nowrap;
		}
	}
}

@media screen and (min-width: $md){
	.InfoError, .InfoErrorT {
		.col-md {
			border-right: 1px solid $gray-medium;
			padding: 1vh 2vh;

			&:last-child{border: none;}
		}
	}

	.InfoErrorT {
		h2 {margin-top: 5vh;}
		h5 {margin-top: 2.5vh;}
	}
}

.organizationMenu, .carousel-indicators {
	li {list-style: none;}
}

.selector-option {
	cursor: pointer;
	width: 100%;
	padding: 10px;
	border: 1px solid #AFAFAF;
	border-radius: $radius;
	margin: 1vh 0;

	.selector{
		float: left;
		font-size: 20px;
		color: $gray-medium
	}

	&.active i {
		transform: rotate(180deg);
	}

	.numeration {
		background: $gold;
		color: $white;
		height: 20px;
		width: 20px;
		font-size: 11px!important;
		letter-spacing: 0;
		border-radius: 50%;
		display: block;
		text-align: center;
		float: left;
		margin-right: 10px;
		line-height: 20px;
	}
}

.card {
	&.approved, &.notApproved {color: $white;}
	&.approved {background: $alert_cl_success;}
	&.notApproved {background: $alert_cl_error;}
}

.text {
	&.approved, &.notApproved, &.pending {
		padding: 1vh 2vh;
		margin-bottom: 2vh;
		color: $white;
	}

	&.pending {background: $alert_cl_information;}
	&.approved {background: $alert_cl_success;}
	&.notApproved {background: $alert_cl_error;}
}
