@import "variables";





.card-publication {
  margin: 1vh 0 3vh;

  * {
    &:hover {text-decoration: none!important;}
  }

  .img-ratio {
    aspect-ratio: 16/9;
    position: relative;
    overflow: hidden;
    border-radius: $radius;

    background: $gray_low;
    view-timeline-name: --revealing-img;

    animation-name: show;
    animation-fill-mode: both;
    animation-timeline: --revealing-img;

    animation-range: entry 0% cover 20%;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  small {
		margin: .5vh 0;
    font-size: 13px!important;
  }

	h6 {text-transform: uppercase;margin: .5vh 0 1vh!important;}
  p {line-height: 1.3;}

  i {
    float: right;
    margin-top: 2vh;
  }

  .box-tag {
    margin-bottom: 1vh;

    .tag {
      margin: .5vh 1vh 0 0!important;
      color: $gray-low;
    }
  }

  &:hover img{filter:blur(1px)}
}
